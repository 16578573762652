@import "../assets/sass/variables";
@import "../assets/sass/mixins";

.media {
  $this: #{&};

  display: flex;
  color: darken($color-tertiary, 10%);
  font-family: $font-family-secondary;

  &:hover {
    color: black;

    #{$this}__title {
      color: $color-primary;
    }
  }

  &__image {
    flex: 0 0 220px;
    width: 220px;
    min-height: 150px;
    max-height: 150px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../assets/article_default.png");
  }

  &__body {
    position: relative;
    width: 100%;
    padding: 2rem;
  }

  &__star {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 20px;
  }
  &__cross {
    position: absolute;
    top: 2.5rem;
    right: 4.5rem;
    font-size: 14px;
  }

  &__title {
    padding-right: 5rem;
    word-break: break-word;
    word-wrap: break-word;
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 40rem) and (max-width: 60rem) {
    &__image {
      flex: 0 0 220px;
      width: 220px;
      min-height: 150px;
      max-height: 150px;
    }
  }
  @media (max-width: 40rem) {
    &__image {
      flex: 0 0 100px;
      max-height: none;
    }
    &__star {
      font-size: 2rem;
    }
    &__cross {
      font-size: 1.5rem;
    }
  }

  @include is-theme("dark") {
    &:hover {
      background-color: lighten($color-primary-dark, 4%);
      color: white;

      #{$this}__title {
        color: inherit;
      }
    }
  }
}