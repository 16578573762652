.hidden {
    display: none;
    visibility: hidden;

    &__xs {
        @media (max-width: 40rem) {
            display: none;
            visibility: hidden;
        }
    }
}