@import "../assets/sass/variables";
@import "../assets/sass/mixins";

.panel {
  background-color: white;
  box-shadow: 0px 16px 27px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 300ms ease-out;

  &--padded {
    padding: 2rem;
  }

  @include is-theme("dark") {
    background-color: $color-primary-dark;
    box-shadow: 0px 16px 27px 0 rgba(0, 0, 0, 0.2);
  }
}