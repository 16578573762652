@import "../assets/sass/variables";

.spinner {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.spinner .cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(0.9);
}

.spinner .cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($color-primary, 0.15);
  animation: foldCube 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.spinner .cube2 {
  transform: scale(0.9) rotateZ(90deg);
}

.spinner .cube3 {
  transform: scale(0.9) rotateZ(180deg);
}

.spinner .cube4 {
  transform: scale(0.9) rotateZ(270deg);
}

.spinner .cube2:before {
  animation-delay: 0.3s;
}

.spinner .cube3:before {
  animation-delay: 0.6s;
}

.spinner .cube4:before {
  animation-delay: 0.9s;
}

@keyframes foldCube {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}


.loader {
    width: 100%;
    height: 100%;
    &__center {
      position: absolute;
      top: 0;
      left: 0;

      .loader__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      .loader__message {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    z-index: 0;
  
    &__message {
      padding: 0 6rem;
      width: 100%;
      text-align: center;
    }
}