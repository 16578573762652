input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
    transition: border-color 300ms ease-out;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 0;
    padding-right: 0;

    body.dark .is-themed & {
        color: white;
        border-color: $border-color-dark;

        &:focus {
            border-color: $color-secondary;
        }
    }
}