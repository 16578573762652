@import "../assets/sass/variables";
@import "../assets/sass/mixins";

.media-list {
  & > * + * {
    // margin-top: 4rem;
  }

  &__new {
    font-size: 19px;
    font-weight: bold;
    color: #4e32b8;
    cursor: pointer;
  }

  &__items {
    & > * + * {
      transition: box-shadow 300ms ease-out;
      box-shadow: inset 0 18px 9px -16px rgba(0, 0, 0, 0.1);
    }
  }

  &__header, &__footer {
    text-align: center;
  }

  &__header {
    margin-bottom: 3rem;
  }

  &__footer {
    margin-top: 4rem;
  }
  
  &__more {
    font-size: 1.2em;
    color: $color-tertiary;
    cursor: pointer;

    &:hover {
      color: black;
    }
  }

  @include is-theme("dark") {
    &__items {
      & > * + * {
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.05);
      }
    }

    &__more {
      &:hover {
        color: white;
      }
    }
  }
}