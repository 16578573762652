@import url('https://fonts.googleapis.com/css?family=Heebo:400,700|Roboto+Condensed:300,400,400i,700');

body {
    font-size: 12px;
    font-family: $font-family;
    color: darken($color-tertiary, 10%);
    -webkit-font-smoothing: antialiased;
    font-smooth: always;

    &.dark .is-themed {
        color: darken($color-tertiary, 20%);

        h1, h2, h3, h4, h5 {
            color: white;
        }
    }
}

h1, h2, h3, h4, h5 {
    color: black;
    font-weight: 400;
    transition: color 200ms ease-out;
    letter-spacing: 0.5px;
}

h2 {
    font-size: 3rem;
}

a {
    transition: color 100ms ease-out;
}