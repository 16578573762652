.splash {
    min-height: 100%;
    background: #eb01a5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 60px;
    background-image: url("../assets/splash-bg.png");
    background-image: url("../assets/splash-bg.png"),
      linear-gradient(145deg, #762b9e 0%, #4235c1 100%);
    background-repeat: no-repeat;
    background-position: left bottom;
  
    &__container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 600px;
    }
  
    &__footer {
      border-color: transparentize(white, 0.75);
    }
  }