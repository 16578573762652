html, body {
    height: 100%;
    min-height: 100%;
}

body {
    transition: background-color 300ms ease-out;
    background-color: $color-quinary;

    &.dark {
        background-color: $color-primary-darker;
    }
    #scrollToTop {
        display: none;
        position: fixed;
        bottom: 20px;
        right: 30px;
        z-index: 99;
        font-size: 30px;
        border: none;
        outline: none;
        background-color: purple;
        color: white;
        cursor: pointer;
        padding: 0px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }
      
      
}