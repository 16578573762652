.white,
.white:active,
.white:focus,
.white:focus-within,
.white:hover,
.white:visited {
  color: white;
}
.nav-overlay {
  display: none;
}
.hidden__xl {
  display: none;
}
.cls-1 {
  fill: #fff;
}
@media (max-width: 40rem) {
  .navbar {
    // height:9vh!important;
    position: fixed !important;
  }
  .theme-toggle-icon {
    float: right !important;
  }
  .hidden__xl {
    display: block;
  }
  .nav-overlay {
    position: fixed;
    bottom: 0;
    right: 0;
    min-height: calc(100vh - 60px + 1px);
    width: 100%;
    z-index: 10;
    background: #eb01a5;
    display: flex;

    background-image: url("../assets/background@2x.png");
    background-image: linear-gradient(90deg, #732ba0 0%, #4a34bc 100%);
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > ul {
      list-style-type: none; 
      letter-spacing: 3px;

      & > li {
        text-align: center; 
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
  }
  .close-x {
    stroke: white;
    fill: transparent;
    stroke-linecap: round;
    stroke-width: 2;
  }
  .icon_shrink {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .frame__heading {
    font-size: 8px !important;
  }
  .navbar__menu {
    // width: 50%;
  }
}
.navbar {
  $this: #{&};

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(145deg, #762b9e 0%, #4235c1 100%);
  box-shadow: 0 8px 35px 0 rgba(0, 0, 0, 0.15);

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;

    &--left {
      & > * {
        border-right: 1px solid transparentize(white, 0.75);
      }
    }

    &--right {
      & > * {
        border-left: 1px solid transparentize(white, 0.75);
      }
    }

    &__item {
      float: left;
      clear: none;
      margin: 0;

      &__title {
        @media (max-width: 60rem) {
          //display: none;
          // max-width: 10rem;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }

  &__submenu {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 5s ease;
    display: none;
    background: linear-gradient(145deg, #762b9e 0%, #4235c1 100%);
    box-shadow: 0 8px 35px 0 rgba(0, 0, 0, 0.15);
    margin-left: -2rem;
    margin-top: 2rem;
    width: 200px;

    &__item {
      display: block;
      float: none;
      clear: both;
      margin: 0;
    }

    :hover > & {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }

  &__select {
    width: 158px;
  }
  &__link {
    display: inline-block;
    padding: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    width: 100%;

    &:active,
    &:focus,
    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.15);
    }

    img {
      vertical-align: middle;
      fill: white;

      &.icon {
        width: 2rem;
      }
    }
  }

  &__redirect {
    font-size: 1.33em;
    padding: 1.75rem 2rem;
  }

  &__logotype {
    padding: 1.5rem;

    img {
      width: auto;
      height: 28px;
      margin-right: 0.5rem;
    }
  }

  &__filters {
    width: 200px;
    & > span {
      margin-left: 6rem;
    }
  }

  &--transparent {
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid transparentize(white, 0.75);

    .navbar__submenu {
      background: rgba(0, 0, 0, 0.15);
    }
  }
}