@import "../assets/sass/variables";
@import "../assets/sass/mixins";

.footer {
  $this: #{&};

  color: $color-tertiary;
  border-top: 1px solid $color-quaternary;
  transition: border-color 300ms ease-out;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
  }

  &__section {
    &--right {
      @media (max-width: 40rem) {
        text-align: right;
      }
    }
  }

  &__nav {
    a {
      color: lighten($color-primary, 33%);

      &:hover {
        color: inherit;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      & > * {
        position: relative;
      }

      & > * + * {
        &:after {
          content: "|";
          position: absolute;
          top: 0;
          left: 0;
          @media (max-width: 40rem) {
            content: none;
            display: none;
            visibility: hidden;
          }
        }
      }

      li {
        float: left;
        clear: none;
        padding: 0 2rem;
        margin: 0;

        &:last-of-type {
          padding-right: 0;
        }

        @media (max-width: 40rem) {
          width: 100%;
          padding-right: 0;
        }
      }
    }
  }

  &--transparent {
    #{$this}__nav {
      a {
        color: white;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @include is-theme("dark") {
    border-color: $color-primary-dark;
    color: lighten($color-primary-dark, 20%);

    #{$this}__nav {
      a {
        color: lighten($color-primary-dark, 20%);

        &:hover {
          color: white;
        }
      }
    }
  }
}