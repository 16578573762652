@import "../assets/sass/variables";

.scroll {
    opacity: 0.5;
    background-color: $color-secondary;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    border: none;
    
    &:hover {
      opacity: 1;
    }
  }
.arrow {
    display: block;
    left: calc(50% - 10px);
    position: relative;

    path {
        fill: $color-initial;
    }
}