.page {
    $this: #{&};

    &__navbar {
        width: 100%;
    }

    &__wrapper {
        padding: 6rem 0 !important;

        @media (max-width: 60rem) {
            padding: 2rem 0;
        }

        #{$this}__content {
            &.column {
                @media (max-width: 60rem) {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }
        }

        #{$this}__sidebar {
            &.column {
                @media (max-width: 60rem) {
                    display: none;
                }
            }
        }
    }
}