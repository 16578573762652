@import "../assets/sass/variables";

.board {
  &__panel {
    padding: 5rem 6rem;
    @media (max-width: 40rem) {
      padding: 2rem 3rem;
    }
  }

  &__header {
    text-align: center;
  }

  &__heading {
    color: white;
    font-family: $font-family;
    font-weight: bold;
  }
}