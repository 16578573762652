.article-frame {
    height: 100vh;
    position: relative;
  
    &__window {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding-top: 60px;
    }
  
    &__title {
      padding-top: 1.9rem;
      padding-bottom: 1.9rem;
      padding-left: 10px;
      padding-right: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // 5 buttons
      max-width: calc(100vw - (66px * 5));
    }
  
    &__heading {
      text-transform: none;
      margin: 0;
      color: white;
      // @media (max-width: 60rem) {
      //     // display: none;
      //     white-space: nowrap;
      //     width: 1rem;
      // }
    }
  
    .navbar {
      $this: #{&};
      &__item {
        margin-bottom: 0 !important;
      }
      &__menu {
        list-style: none;
        padding: 0;
        margin: 0;
  
        &--left {
          & > * {
            border-right: none;
          }
        }
  
        &--right {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }