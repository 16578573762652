@import "assets/sass/variables";
@import "assets/sass/app";

.app {
  padding-top: 60px;
  min-height: 100vh;

  &--article {
    height: 100%;
    overflow: hidden;
    padding: 0;
  }

  &--login,
  &--forgot,
  &--reset,
  &--register {
    height: 100%;
    padding-top: 0;
  }
}

.full-screen .app {
  padding-top: 0;
  overflow: hidden;
}

.join {
  $this: #{&};

  width: 100%;

  &__wrapper {
    justify-content: center;

    #{$this}__content {
      @media (max-width: 60rem) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
}