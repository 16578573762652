@import "../assets/sass/variables";

.mobile-app {
  text-align: center;
  padding: 4rem 0;

  &__heading {
    font-family: $font-family;
  }

  &__button {
    width: 100%;
  }

  &__image {
    vertical-align: bottom;
  }
}