.form {
    margin-bottom: 0;

    & > * + * {
        margin-top: 3rem;
    }

    &__fieldset {
        margin-bottom: 0;

        & > * + * {
            margin-top: 1rem;
        }
    }

    &__field {
        position: relative;

        a {
            position: absolute;
            top: 0; right: 0;
            padding: 1rem 0;
        }

        &__errors {
            color: #602fad;
        }
    }

    &__input {
        margin-bottom: 0;
    }

    &__button {
        margin-bottom: 0;
        display: block;
        width: 100%;

        &--facebook {
            background-color: #395BA4;
            border-color: #395BA4;

            &:hover {
                background-color: #2D4A87;
                border-color: #2D4A87;
            }
        }
    }

    &__description {
        text-align: center;
        margin-top: 2rem;

        a {
            text-decoration: underline;
            color: inherit;

            &:hover {
                color: $color-primary;
            }
        }
    }
}