$color-initial: #fff !default;
$color-primary: #4235C1 !default;
$color-primary-dark: #322B3C !default;
$color-primary-darker: #282430 !default;
$color-secondary: #762B9E !default;
$color-tertiary: #B2B2B2 !default;
$color-quaternary: #E1E1E1 !default;
$color-quinary: #f5f5f5 !default;

$color-facebook: #395BA4 !default;
$color-google: #ea4335 !default;
$color-apple: #8e8e93 !default;

$border-color-dark: darken($color-tertiary, 33%) !default;

$font-family: 'Heebo', sans-serif;
$font-family-secondary: 'Roboto Condensed', sans-serif;