.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
    border-radius: 0;
    font-size: 1.05em;
    text-transform: none;
    font-weight: normal;
    padding: 0 2rem;
    height: auto;
    transition:
        color 100ms ease-out,
        background-color 100ms ease-out,
        border-color 100ms ease-out;
}

.button.button-large,
button.button-large,
input[type='button'].button-large,
input[type='reset'].button-large,
input[type='submit'].button-large {
    padding: 0.5rem 3rem;

    @media (max-width: 80rem) {
        padding: 0.5rem 2rem;
    }
}

.button.button-outline,
button.button-outline,
input[type='button'].button-outline,
input[type='reset'].button-outline,
input[type='submit'].button-outline {
    color: $color-tertiary;
    border-color: $color-tertiary;

    body.dark .is-themed & {
        color: darken($color-tertiary, 15%);
        border-color: darken($color-tertiary, 33%);

        &:hover {
            color: white;
            border-color: darken($color-tertiary, 15%);
        }
    }
}

.button.button-facebook,
button.button-facebook,
input[type='button'].button-facebook,
input[type='reset'].button-facebook,
input[type='submit'].button-facebook {
    background-color: $color-facebook;
    border-color: $color-facebook;

    &.active,
    &:active,
    &:focus,
    &:hover {
        background-color: darken($color-facebook, 5%);
        border-color: darken($color-facebook, 5%);
    }
}

.button.button-google,
button.button-google,
input[type='button'].button-google,
input[type='reset'].button-google,
input[type='submit'].button-google {
    background-color: $color-google;
    border-color: $color-google;

    &.active,
    &:active,
    &:focus,
    &:hover {
        background-color: darken($color-google, 5%);
        border-color: darken($color-google, 5%);
    }
}


.button.button-apple,
button.button-apple,
input[type='button'].button-apple,
input[type='reset'].button-apple,
input[type='submit'].button-apple {
    background-color: $color-apple;
    border-color: $color-apple;

    &.active,
    &:active,
    &:focus,
    &:hover {
        background-color: darken($color-apple, 5%);
        border-color: darken($color-apple, 5%);
    }
}